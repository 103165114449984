import React, { useCallback, useState } from "react";
import { useUserId } from "../../utils/hooks/useUserId"
import { Button, Input } from "antd";
import "../../css/AdminCreateUser.sass";
import { createUser, getUser } from "../../utils/server/serverAPI";
import { PlayerId } from "../../state/state";

export function AdminCreateUser() {
  const [ state, setState ] = useState({
    username: "",
    id: "",
    password: "",
    errorText: ""
  });
  const userId = useUserId();

  const handleChangeUsername = useCallback((evt) => {
    const username = (evt.target as any).value;
    setState({ ...state, username });
  }, [state]);

  const handleChangeId = useCallback((evt) => {
    const id = (evt.target as any).value;
    setState({ ...state, id: id });
  }, [state]);

  const handleChangePassword = useCallback((evt) => {
    const password = (evt.target as any).value;
    setState({ ...state, password });
  }, [state]);

  let errorText;

  const handleSubmit = useCallback(async () => {
    const user = await getUser(state.id as PlayerId);

    if (user == null) {
      createUser({
        username: state.username,
        id: state.id,
        password: state.password
      })
    } else {
      console.log("ATTEMPT???")
      setState({ ...state, errorText: "User with that Fetlife ID already exists." })
    }
  }, [state, setState])

  // if (userId !== "-1") {
  //   return <div>Sorry, you do not have access to this page. Please contact an admin.</div>
  // }

  return (
    <div className="admin-create-user-page">
      <div className="header">Create a User</div>
      {state.errorText != "" && <div className="error">{state.errorText}</div>}
      <div>
        <div>Fetlife Username:</div>
        <Input onChange={handleChangeUsername} />
      </div>
      <div>
        <div>Fetlife Id:</div>
        <Input onChange={handleChangeId} />
      </div>
      <div>
        <div>Password:</div>
        <Input onChange={handleChangePassword} />
      </div>
      <Button className="submit-button" onClick={handleSubmit}>Submit</Button>
    </div>
  );
}
import React from 'react';
import { CommentCard } from "./CommentCard";
import { Comment } from "../../../state/state";
import "../../../css/CommentsSection.sass";

import { usePlayerIdFromUrl } from '../../../utils/hooks/usePlayerIdFromUrl';

export function CommentsSection({ isAboutFlag, comments, reloadComments }: { isAboutFlag: boolean; comments: Comment[], reloadComments: any }) {
  const playerId = usePlayerIdFromUrl();

  if (comments.length == 0) {
    return <div className="comments-section-no-comments"><div>No comments yet. :( </div><div>Be the first to leave a comment!</div></div>
  }

  return (
    <div className={"comments-section"}>{comments.map((comment, i) => (
      <CommentCard comment={comment} isAboutFlag={isAboutFlag} reloadComments={reloadComments} />
    ))}</div>
  )
}
import React, { useContext, useCallback, useState } from "react";
import "./css/AppHeader.sass";
import { InputGroup } from "@blueprintjs/core";
import { useNavigateToUserPage } from "./utils/hooks/navigation/useNavigateToUserPage";
import { useNavigateToLoginPage } from "./utils/hooks/navigation/useNavigateToLoginPage";
import { useNavigateToHome } from "./utils/hooks/navigation/useNavigateToHome";
import { MercyContext } from "./state/MercyContext";
import { useGetUserFetlifeUsername } from "./utils/hooks/useGetUserFetlifeUsername";
import { useNavigateToPlayerPage } from "./utils/hooks/navigation/useNavigateToPlayerPage";

import logo from './resources/images/logo.png';

export function AppHeader({ clearToken }) {
  const [text, setText] = useState("");
  const { dispatch } = useContext(MercyContext);
  const navigateToPlayerPage = useNavigateToPlayerPage();
  const navigateToHome = useNavigateToHome();
  const navigateToLoginPage = useNavigateToLoginPage();
  const navigateToUserPage = useNavigateToUserPage();

  const handleChange = useCallback((evt) => {
    const text = (evt.target as any).value;
    setText(text);
  }, [setText])

  const handlePressEnter = useCallback((evt) => {
    const text = (evt.target as any).value;
    if (evt.key != "Enter") {
      return;
    }
    navigateToPlayerPage(text);
  }, [navigateToPlayerPage])

  const handleLogout = useCallback(() => {
    clearToken();
    dispatch({ type: "RESET_USER" })
    navigateToHome();
  }, [dispatch, clearToken, navigateToLoginPage]);

  const username = useGetUserFetlifeUsername();

  return (
    <div className="app-header">
      <div className="logo" onClick={navigateToHome}>
        <img src={logo} />
      </div>
      <InputGroup
        className="search"
        type="search"
        leftIcon="search"
        onChange={handleChange}
        placeholder="Search SafeWxrds"
        onKeyDown={handlePressEnter}
      />
      <div className="app-header-right">
        <div onClick={navigateToUserPage}>{username}</div>
        <div onClick={handleLogout}>Logout</div>
      </div>
    </div>
  )
}
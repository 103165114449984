import React, { useCallback, useEffect, useState, useContext} from 'react';
import { MercyContext } from '../../state/MercyContext';
import { useUserId } from './useUserId';
import { useGetFetlifeUsername } from './useGetFetlifeUsername';

export function useGetUserFetlifeUsername() {
  const { state, dispatch } = useContext(MercyContext);
  const userId = useUserId();

  return useGetFetlifeUsername(userId);
}
import { UserId, BASE_URL, PlayerId, LoginCredentials, CommentId } from "../../state/state";
import { convertServerUserToAppPlayer, convertServerUserToAppUser, convertServerFetlifeToAppFetlife } from "./serverToAppTranforms";
import { ServerUser } from "../../state/server/serverState";

const API_URL = `${BASE_URL}/api`

async function fetchUser(userId: PlayerId | UserId) {
  const url = `${API_URL}/users/${userId}`;

  const response = await fetch(url);
  const users = await response.json();

  if (users.length === 0) {
    console.warn(`Could not get user with id: ${userId}`)
    return undefined;
  }

  return users[0];
}


export async function getPlayer(playerId: PlayerId) {
  const player = await fetchUser(playerId);

  return player != null ? convertServerUserToAppPlayer(player): undefined;
}

export async function getUser(userId: UserId) {
  const user = await fetchUser(userId);

  return user != null ? convertServerUserToAppUser(user) : undefined;
}

export async function getFetlifeUsers() {
  const url = `${API_URL}/fetlife`
  console.log("FETLIFE URL: ", url)

  const response = await fetch(url);
  const fetlifeUsers = await response.json();

  return convertServerFetlifeToAppFetlife(fetlifeUsers);
}

export async function loginUser(credentials: LoginCredentials) {
  const url = `${BASE_URL}/login`

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })

  if (response.status === 500) {
    return undefined;
  } else {
    return response.json();
  }
}

export function createUser({ username, id, password
}: { username: string; id: string; password: string;}) {
  const url = `${API_URL}/users/create`;

  return fetch(url, {
    method: "POST",
    body: JSON.stringify({
      fetlife_id: id,
      fetlife_username: username,
      password,
    }),
    headers: {
      'Content-Type': 'application/json'
    },
  }).catch(err => console.error(err)).then((d) => console.log("DID IT!!!"));

}


 export function createNewComment(text: string, isAbout: string, createdBy: string) {
  const url = `${API_URL}/comments/create`;

  return fetch(url, {
    method: "POST",
    body: JSON.stringify({
      created_by_user: createdBy,
      is_about_user: isAbout,
      comment: text,
    }),
    headers: {
      'Content-Type': 'application/json'
    },
  }).catch(err => console.error(err));
}


export function deleteComment(commentId: CommentId) {
  const url = `${API_URL}/comments/delete/${commentId}`;

  return fetch(url, {
    method: "DELETE",
  }).catch(err => {
    console.error(`Could not delete comment with ID: ${commentId}\n Server Error: ${err}`);
  })
}

export function flagComment(commentId: CommentId) {
  const url = `${API_URL}/comments/flag/${commentId}`;

  return fetch(url, {
    method: "POST",
    body: JSON.stringify({
      comment_id: commentId,
    }),
  }).catch(err => {
    console.error(`Could not delete comment with ID: ${commentId}\n Server Error: ${err}`);
  })
}
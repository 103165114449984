import React, { useState, useCallback, useContext} from 'react';
import { noop } from "lodash";
import { Card, Icon } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import { Comment } from "../../../state/state";
import "../../../css/CommentCard.sass";
import classNames from "classnames";
import { MercyContext } from '../../../state/MercyContext';
import { useUserId } from '../../../utils/hooks/useUserId';
import { flagComment } from '../../../utils/server/serverAPI';

export function CommentFlag({ comment }: { comment: Comment }) {
  const isFlagged = comment.flaggedAt != null;
  const userId = useUserId();
  const isCommentAboutUser = comment.isAbout === userId;

  console.log("COMMENT FLAG: ", comment)

  return isFlagged ?
    <FlaggedCommentFlag isCommentAboutUser={isCommentAboutUser} /> :
    <UnflaggedCommentFlag isCommentAboutUser={isCommentAboutUser} commentId={comment.commentId} />;
}

function FlaggedCommentFlag({ isCommentAboutUser }) {
  const text = isCommentAboutUser ?
    "You have flagged this comment as inaccurate. Click the icon to unflag."
    : "The fetlife player has flagged this comment as inaccurate. Please contact the parties involved for more information.";

  return <BaseCommentFlag
    isPinned={true}
    hoverText={text}
    onClick={noop}
    />;
}

function UnflaggedCommentFlag({ isCommentAboutUser, commentId }) {
  const text = isCommentAboutUser ? "Click to flag comment" : "";

  console.log("commentId: ", commentId)

  const handleClick = useCallback(() => {
    // TODO - set comment as flagged
    console.log("set as flagged")
    flagComment(commentId)
  }, [commentId])

  if (!isCommentAboutUser) {
    return null;
  }
  return (
    <BaseCommentFlag
      isPinned={false}
      hoverText={text}
      onClick={handleClick}
    />
  )
}

function BaseCommentFlag({ onClick, hoverText, isPinned }) {
  return (
    <Tooltip2
      content={hoverText}
      >
      <Icon
        icon="flag"
        className={classNames("comment-flag", {
          "is-editable": !isPinned
        })}
        onClick={onClick}/>
    </Tooltip2>
  );
}
import React, { useCallback, useEffect, useState, useContext, } from 'react';
import { useLocation } from "react-router-dom";
import { Tabs, Tab } from "@blueprintjs/core";
import { CommentsAboutPlayerSection } from "./comments-section/CommentsAboutPlayerSection";
import { CommentsByPlayerSection } from "./comments-section/CommentsByPlayerSection";
import { convertServerCommentToAppComment } from "../../utils/server/serverToAppTranforms";
import { ServerComment } from "../../state/server/serverState";
import { BASE_URL } from '../../state/state';
import { LeaveCommentButton } from "./LeaveCommentButton";
import { usePlayerIdFromUrl } from "../../utils/hooks/usePlayerIdFromUrl";
import { MercyContext } from '../../state/MercyContext';
import { useGetFetlifeUsername } from "../../utils/hooks/useGetFetlifeUsername";
import { getPlayer } from "../../utils/server/serverAPI";
import { useUserId } from '../../utils/hooks/useUserId';
import "../../css/PlayerPage.sass";
import { PlayerTag } from '../../common/PlayerTag';

const PlayerPageTabs = {
  ABOUT: "ABOUT",
  BY: "BY",
}

export function PlayerPage(props) {
  const [state, setState] = useState({
    player: undefined,
    activeTab: PlayerPageTabs.ABOUT,
  });
  const username = useGetFetlifeUsername((state.player || {}).flId);

  const playerIdTemp = usePlayerIdFromUrl()
  const userId = useUserId()
  const playerId = playerIdTemp ? playerIdTemp : userId;

  useEffect(() => {
    getPlayer(playerId).then(user => {
      setState(state => ({
        ...state,
        player: user
      }))
    });
  }, [playerId])

  const handleTabChange = useCallback((newTab) => {
    setState(state => ({
      ...state,
      activeTab: newTab
    }))
  }, []);


  if (state.player == null) {
    return <div className="error-page">Sorry, player {playerId} does not exist. Please search for another player.</div>;
  }

  return (
    <div>
      <Tabs
        className="player-page-tabs"
        onChange={handleTabChange}
        selectedTabId={state.activeTab}
        vertical={true}
        renderActiveTabPanelOnly={true}
      >
        <div className="bp4-tab user">{username}</div>
        <Tab id={PlayerPageTabs.ABOUT} title={`Comments about ${username}`} panel={<CommentsAboutPlayerSection />} />
        <Tab id={PlayerPageTabs.BY} title={`Comments by ${username}`} panel={<CommentsByPlayerSection />} />
        <LeaveCommentButton />
      </Tabs>
    </div>
  );
}
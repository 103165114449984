
import { Icon } from "@blueprintjs/core";
import React, { useCallback } from "react";
import { Comment } from "../../../state/state";
import { useUserId } from "../../../utils/hooks/useUserId";
import { deleteComment } from "../../../utils/server/serverAPI";


export function DeleteCommentIcon({ comment, reloadComments }: { comment: Comment; reloadComments: any; }) {
  const userId = useUserId();

  const handleClick = useCallback(() => {
    deleteComment(comment.commentId).then(d => {
      reloadComments();
    })
  }, [comment.commentId, reloadComments])

  if (userId != comment.writtenBy) {
    return null;
  }

  return <Icon
    className="delete-comment-icon"
    icon="trash"
    onClick={handleClick}
  />
}
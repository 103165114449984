import React, { useContext, useState, useCallback } from 'react';
import classNames from "classnames";
import PropTypes from 'prop-types';
import { loginUser, getUser } from '../../utils/server/serverAPI';
import { useInitializeUser } from "../../utils/hooks/useInitializeUser";

import "../../css/LoginPage.sass";
import { PlayerId, UserId } from '../../state/state';
import { MercyContext } from '../../state/MercyContext';
import logo from '../../resources/images/logo.png';
import { useNavigate } from 'react-router-dom';
import { MercyRoutes } from '../../state/routes';

async function initializeUser(userId: UserId, dispatch) {
  const user = await getUser(userId);
  dispatch({ type: "SET_USER", payload: user})
}

export function LoginPage({ setToken, dispatch }) {
  const [userId, setUserId] = useState<PlayerId>();
  const [password, setPassword] = useState();
  const [isInvalid, setIsInvalid] = useState(false);
  const navigate = useNavigate();

  const handleClickContinue = useCallback((e) => {
    e.preventDefault();
    console.log("USER ID: ", userId)
    console.log("PASSWORD: ", password)
    if (userId == null || password == null) {
      setIsInvalid(true);
      return;
    }

    loginUser({
      user_id: userId,
      password
    }).then(response => {
      if (response == null || response.token == null) {
        setIsInvalid(true);
      } else {
        setToken(response);
        initializeUser(userId, dispatch);
      }
    })
  }, [userId, password]);

  const handleClickForgotPassword = useCallback(() => {
    navigate(MercyRoutes.RESET_PASSWORD)
  }, [navigate])
  const handleClickNewAccount = useCallback(() => {
    navigate(MercyRoutes.CREATE_ACCOUNT)}
  , [navigate]);
  const handleClickAboutUs = useCallback(() => {
    console.log("NEED TO CREATE ABOUT US PAGE")
  }, []);

  return(
    <div className="login-page">
        <form className="login-form">
          <input className="form-item" type="text" placeholder="Fetlife User ID" onChange={e => setUserId(e.target.value)}/>
          <input className="form-item" type="password" placeholder="Password" onChange={e => setPassword(e.target.value)}/>
          <div className={classNames("error-prompt", { "is-invalid": isInvalid })}>There was a problem logging in. Please try again.</div>
          <button onClick={handleClickContinue} className="submit-button form-item">Continue</button>
          <div className="forgot-password" onClick={handleClickForgotPassword}>Forgot password?</div>
        </form>
        <div className="divider" />
        <button onClick={handleClickNewAccount} className="new-account-button form-item">Create New Account</button>
      {/* </div> */}
      <div className="about-us" onClick={handleClickAboutUs}>About Us</div>
    </div>
  )
}
import { Comment, PlayerId, User, CommentId, Player, UserId, FetlifeUsers } from "../../state/state";
import { keyBy, mapValues } from "lodash";
import { ServerComment, ServerUser, ServerFetlife } from "../../state/server/serverState";

export function convertServerCommentToAppComment(comment: ServerComment): Comment {
  return {
    writtenBy: comment.created_by_user as PlayerId,
    isAbout: comment.is_about_user as PlayerId,
    createdAt: new Date(comment.created_at),
    flaggedAt: comment.flagged_at ? new Date(comment.flagged_at ) : undefined,
    lastModified: new Date(comment.last_edited),
    comment: comment.comment,
    commentId: comment.id as CommentId,
  };
}

export function convertServerUserToAppPlayer(player: ServerUser): Player {
  return {
    flId: player.fl_id as PlayerId,
    flUsername: player.fl_username,
  };
}

export function convertServerUserToAppUser(user: ServerUser): User {
  return {
    flId: user.fl_id as UserId,
    flUsername: user.fl_username,
  };
}


export function convertServerFetlifeToAppFetlife(users: ServerFetlife[]): FetlifeUsers {
  const idToUser = keyBy(users, "fl_id");
  return mapValues(idToUser, (v: ServerFetlife) => v.fl_username);
}

import React, { useCallback, useEffect, useState, useContext} from 'react';
import { MercyContext } from '../../state/MercyContext';
import { usePlayerIdFromUrl } from './usePlayerIdFromUrl';
import { PlayerId } from '../../state/state';

export function useGetFetlifeUsername(flId?: PlayerId) {
  const { state, dispatch } = useContext(MercyContext);

    if (flId == null) {
      console.warn("[useGetFetlifeUsername] Fetlife id is undefined.")
      return;
    }

    const username = state.fetlifeUsers[flId];

    if (username == null) {
      console.warn(`[useGetFetlifeUsername] A Fetlife username does not exist for fetlife_id ${flId}`)
      return;
    }

    return username;
}
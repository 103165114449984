import { useCallback } from "react";
import { useNavigate } from "react-router";
import { PlayerId } from "../../../state/state";
import { useUserId } from "../useUserId";
import { useNavigateToPlayerPage } from "./useNavigateToPlayerPage";


export function useNavigateToUserPage() {
  const userId = useUserId();
  const navigateToPlayerPage = useNavigateToPlayerPage();

  return useCallback(() => navigateToPlayerPage(userId), [userId, navigateToPlayerPage]);
}
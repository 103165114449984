import React, { useEffect } from 'react';
import './css/App.sass';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import { PlayerPage } from "./pages/player-page/PlayerPage";
import { HomeSearch } from "./pages/home-page/HomeSearch";
import { MercyContext } from "./state/MercyContext";
import { LeaveCommentPage } from "./pages/leave-comment-page/LeaveCommentPage";
import { LoginPage } from "./pages/login/LoginPage";
import { MERCY_INITIAL_STATE, mercyReducer } from "./state/mercyReducer";
import { MercyRoutes, getPlayerPageRouteForPlayer } from "./state/routes";
import { AppHeader } from "./AppHeader";
import { BASE_URL } from './state/state';
import { convertServerUserToAppUser, convertServerFetlifeToAppFetlife } from './utils/server/serverToAppTranforms';
import { useInitializeState } from "./useInitializeState";
import { useToken } from "./utils/hooks/useToken";
import { useNavigate } from 'react-router';
import { useUserId } from './utils/hooks/useUserId';
import { initializeRepl } from "./initializeRepl";
import { AdminCreateUser } from './pages/admin/AdminCreateUser';
import { UnauthedApp } from "./UnauthedApp";

function App() {
  const [state, dispatch] = React.useReducer(mercyReducer, MERCY_INITIAL_STATE);

  const { token, setToken } = useToken();

  useInitializeState(dispatch);
  initializeRepl(() => state);

  if (token == null || state.user == null) {
    return <Router><UnauthedApp setToken={setToken} dispatch={dispatch} /></Router>
  }

  return (
    <MercyContext.Provider value={{ state, dispatch }}>
    <div className="App">
      <Router>
        <AppHeader clearToken={() => setToken(undefined)}/>
        <div className="app-header-space"></div>
        <Routes>
          <Route path={MercyRoutes.ADMIN}>
            <Route path={"createUser"} element={<AdminCreateUser />}/>
          </Route>
          <Route path='/' element={<Navigate to={getPlayerPageRouteForPlayer(state.user.flId)} />} />
          <Route path={MercyRoutes.PLAYER_PAGE} element={<PlayerPage />} />
          <Route path={MercyRoutes.LEAVE_COMMENT} element={<LeaveCommentPage />} />
        </Routes>
      </Router>
    </div>
    </MercyContext.Provider>
  );
}

export default App;

import React from "react";
import { Button } from "@blueprintjs/core";
import { useNavigate } from 'react-router-dom';
import { PlayerId } from "../../state/state";
import { usePlayerIdFromUrl } from "../../utils/hooks/usePlayerIdFromUrl";
import { MercyRoutes } from "../../state/routes";

export function LeaveCommentButton() {
  const navigate = useNavigate();
  const playerId = usePlayerIdFromUrl();

  const handleClick = () => {
    navigate(MercyRoutes.LEAVE_COMMENT.replace(":player_id", playerId));
  }

  return (
    <Button className="leave-comment-button" onClick={handleClick} text="Leave a comment" />
  )
}
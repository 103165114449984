import { UserToken } from '../../state/state';
import { useState } from 'react';

export const getToken = () => {
  const tokenString = sessionStorage.getItem('token');

  if (tokenString == null) {
    return undefined;
  }

  const userToken = JSON.parse(tokenString) as UserToken;

  return (userToken || {}).token;
};

export function useToken() {
  const [token, setTokenState] = useState(getToken());

  const setToken = (userToken: UserToken | undefined) => {
    if (userToken == null) {
      sessionStorage.removeItem("token");
      setTokenState(undefined);
    } else {
      sessionStorage.setItem('token', JSON.stringify(userToken));
      setTokenState(userToken.token);
    }
  };

  return {
    setToken,
    token
  }
}
import React, { useContext, useCallback, useState } from "react";
import { usePlayerIdFromUrl } from "../../utils/hooks/usePlayerIdFromUrl";
import { TextArea, Button } from "@blueprintjs/core";
import { BASE_URL, FetlifeId } from "../../state/state";
import { getPlayerPageRouteForPlayer, MercyRoutes } from "../../state/routes";
import { MercyContext } from "../../state/MercyContext";
import { useGetFetlifeUsername } from "../../utils/hooks/useGetFetlifeUsername";
import { useNavigateToPlayerPage } from "../../utils/hooks/navigation/useNavigateToPlayerPage";
import { PlayerTag } from "../../common/PlayerTag";
import { createNewComment } from "../../utils/server/serverAPI";

import classNames from "classnames";

// import "../../css/LeaveCommentPage.sass";
import "../../css/LoginPage.sass";
import logo from '../../resources/images/logo.png';
import { Navigate, useNavigate } from "react-router-dom";

export function ResetPasswordPage() {
  const [userId, setUserId] = useState();
  const [password, setPassword] = useState();
  const [passwordRetype, setPasswordRetype] = useState();
  const [errorText, setErrorText] = useState("");
  const navigate = useNavigate();

  const handleClickCreateAccount = useCallback((e) => {
    e.preventDefault();
    if (password !== passwordRetype) {
      setErrorText("Passwords do not match. Please retype password.");
    }
    console.log("CREATE AN ACCOUNT!!!!");
  }, [userId, password, passwordRetype, setErrorText]);

  const handleClickBackToLogin = useCallback(() => {
    navigate(MercyRoutes.LOGIN);
  }, [navigate])

  return (
    <div className="create-account-page">
      <div className="login-widget">
        <div className="header">
          <img className="logo" src={logo} />
          <div className="title">Create SafeWxrds Account</div>
        </div>
        <form className="login-form">
          <div className="disclaimer">Note: Although the username is linked with a Fetlife account, the password is not. SafeWxrds needs a separate password to login.</div>
          <input className="form-item" type="text" placeholder="Existing Fetlife Username" onChange={e => setUserId(e.target.value)}/>
          <input className="form-item" type="password" placeholder="New SafeWxrds Password" onChange={e => setPassword(e.target.value)}/>
          <input className="form-item" type="password" placeholder="Retype Password" onChange={e => setPasswordRetype(e.target.value)}/>
          <div className={classNames("error-prompt", { "is-invalid": !!errorText })}>. Please try again.</div>
          <button onClick={handleClickCreateAccount} className="submit-button form-item">Create Account</button>
          <div className="forgot-password" onClick={handleClickBackToLogin}>Back to login</div>
        </form>
      </div>
    </div>
  )
}
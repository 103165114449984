import React, { useState, useCallback } from 'react';
import { Card, Icon } from "@blueprintjs/core";
import { Comment } from "../../../state/state";
import "../../../css/CommentCard.sass";
import classNames from "classnames";
import { CommentFlag } from "./CommentFlag";
import { useGetFetlifeUsername } from '../../../utils/hooks/useGetFetlifeUsername';
import { formatAsLocalTime } from "../../../utils/formatAsLocalTime";
import { useNavigateToPlayerPage } from '../../../utils/hooks/navigation/useNavigateToPlayerPage';
import { PlayerTag } from "../../../common/PlayerTag";
import { DeleteCommentIcon } from './DeleteCommentIcon';

function Attribution({ comment, isAboutFlag }: { comment: Comment, isAboutFlag: boolean }) {
  const { writtenBy, createdAt, isAbout } = comment;

  if (isAboutFlag) {
    return <div className="footer">Written about <PlayerTag playerId={isAbout} /> on {formatAsLocalTime(createdAt)}</div>
  }

  return <div className="footer">Written by <PlayerTag playerId={writtenBy} /> on {formatAsLocalTime(createdAt)}</div>

}

export function CommentCard({ comment, isAboutFlag, reloadComments }: { comment: Comment, isAboutFlag: boolean, reloadComments: any }) {
  return (
    <Card elevation={1} className="comment-card">
      <div className="comment-card-header">
        <Attribution comment={comment} isAboutFlag={isAboutFlag} />
        <DeleteCommentIcon comment={comment} reloadComments={reloadComments} />
        <CommentFlag comment={comment} />
      </div>
      <div className="comment">{comment.comment}</div>
    </Card>
  );
}
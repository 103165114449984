import React, { useContext, useCallback, useState } from "react";
import { usePlayerIdFromUrl } from "../../utils/hooks/usePlayerIdFromUrl";
import { TextArea, Button } from "@blueprintjs/core";
import { BASE_URL, FetlifeId } from "../../state/state";
import { useNavigate } from "react-router";
import { getPlayerPageRouteForPlayer } from "../../state/routes";
import { MercyContext } from "../../state/MercyContext";
import { useGetFetlifeUsername } from "../../utils/hooks/useGetFetlifeUsername";
import { useNavigateToPlayerPage } from "../../utils/hooks/navigation/useNavigateToPlayerPage";
import { PlayerTag } from "../../common/PlayerTag";
import { createNewComment } from "../../utils/server/serverAPI";
import "../../css/LeaveCommentPage.sass";

export function LeaveCommentPage() {
  const { state } = useContext(MercyContext);
  const [text, setText] = useState();
  const playerId = usePlayerIdFromUrl();
  const navigateToPlayerPage = useNavigateToPlayerPage();

  const handleChange = useCallback((evt) => {
    setText((evt.target as any).value);
  })

  const handleSubmit = useCallback(() => {
    createNewComment(text, playerId, state.user.flId);
    // TODO - error handling of bad comment
    navigateToPlayerPage(playerId);
  }, [text, playerId, state])

  return (
    <div className="leave-comment-page">
      <div className="leave-comment-header">Leave a comment about <PlayerTag playerId={playerId} /></div>
      <TextArea
        className="leave-comment-text-area"
        fill={true}
        onChange={handleChange}
        value={text}
      />
      <Button className="submit-button" onClick={handleSubmit} text={"Submit"} />
    </div>
  )
}
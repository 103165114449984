import React, { useCallback } from "react";
import { PlayerId, UserId } from "../state/state";
import { useGetFetlifeUsername } from "../utils/hooks/useGetFetlifeUsername";
import { useNavigateToPlayerPage } from "../utils/hooks/navigation/useNavigateToPlayerPage";

export function PlayerTag({ playerId }: { playerId : PlayerId }) {
  const fetlifeUsername = useGetFetlifeUsername(playerId as string as PlayerId);
  const navigateToPlayerPage = useNavigateToPlayerPage();

  const handleClick = useCallback(() => {
    navigateToPlayerPage(playerId);
  })

  return <a onClick={handleClick}>{fetlifeUsername}</a>;
}
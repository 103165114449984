import React, { useEffect, useContext } from 'react';
import './css/App.sass';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { MercyContext } from "./state/MercyContext";
import { mercyReducer } from "./state/mercyReducer";
import { MercyRoutes } from "./state/routes";
import { UserId } from './state/state';
import { convertServerUserToAppUser, convertServerFetlifeToAppFetlife } from './utils/server/serverToAppTranforms';
import { getUser, getFetlifeUsers } from './utils/server/serverAPI';

export function useInitializeState(dispatch: (a: any) => any) {
  useEffect(() => {
    async function initializeFetlifeUsers() {
      const fetlifeUsers = await getFetlifeUsers();
      dispatch({ type: "SET_FETLIFE_USERS", payload: fetlifeUsers})
    }
    initializeFetlifeUsers();
  }, [dispatch])
}
// export const BASE_URL = 'http://localhost:8080';
export const BASE_URL = 'https://mercy-server.onrender.com';

export type PlayerId = string & { __brand: "playerId"; }
export type CommentId = string & { __brand: "commentId"; }
export type UserId = PlayerId;

export interface Player {
  flId: PlayerId;
  flUsername: string;
}

export interface User extends Player {}

export interface Comment {
  writtenBy: PlayerId;
  isAbout: PlayerId;
  createdAt: Date;
  flaggedAt?: Date;
  lastModified: Date;
  comment: string;
  commentId: CommentId;
}

export interface FetlifeUsers { [flId: string]: string; }

export interface LoginCredentials {
  user_id: string;
  password: string;
}

export interface UserToken {
  token: string;
}
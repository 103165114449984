import { useCallback } from "react";
import { useNavigate } from "react-router";
import { getPlayerPageRouteForPlayer } from "../../../state/routes";
import { PlayerId } from "../../../state/state";


export function useNavigateToPlayerPage() {
  const navigate = useNavigate();

  return useCallback((playerId: PlayerId) => navigate(getPlayerPageRouteForPlayer(playerId)), [navigate]);
}
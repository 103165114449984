import React, { useCallback, useEffect, useState } from 'react';
import { CommentCard } from "./CommentCard";
import { Comment, PlayerId, BASE_URL } from "../../../state/state";

import { ServerComment } from "../../../state/server/serverState";
import { convertServerCommentToAppComment } from "../../../utils/server/serverToAppTranforms";
import { usePlayerIdFromUrl } from '../../../utils/hooks/usePlayerIdFromUrl';
import { CommentsSection } from './CommentsSection';

export function searchCommentsAboutPlayer(query) {
  const url = `/api/comments/commentsAboutUser/${query}`;

  return fetch(url).then(response => response.json() as any as ServerComment[]);
}

export function CommentsAboutPlayerSection() {
  const [comments, setComments] = useState([]);

  const playerId = usePlayerIdFromUrl();

  const loadComments = useCallback(() => {
    searchCommentsAboutPlayer(playerId).then(comments => {
      setComments(comments.map(convertServerCommentToAppComment));
    })
  }, [playerId, setComments])

  useEffect(loadComments, [loadComments]);

  return (
    <CommentsSection
      comments={comments}
      isAboutFlag={false}
      reloadComments={loadComments}
    />
  );
}
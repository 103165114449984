import { PlayerId, User } from "./state";

export interface MercyGlobalState {
  user?: User;
  fetlifeUsers: { [fetlife_id: string]: string };
  comments?: Comment[];
}

export const MERCY_INITIAL_STATE: MercyGlobalState = {
  user: undefined,
  fetlifeUsers: {},
};

export const mercyReducer = (state: MercyGlobalState, action: any) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.payload
      }
    case "RESET_USER":
      return {
        ...state,
        user: undefined
      }
    case "SET_FETLIFE_USERS":
      return {
        ...state,
        fetlifeUsers: action.payload
      }
    case "SET_COMMENTS":
      return {
        ...state,
        comments: action.payload
      }
    default:
      return state;
  }
};